import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Routes, Navigate, Switch} from 'react-router-dom';
import Home from './Containers/Home';
import Register from './Containers/Register';
import Dashboard from './Containers/Dashboard';
import CompleteProfile from './Containers/CompleteProfile';
import BankDetail from './Containers/BankDetail';
import InvestorConcent from './Containers/InvestorConcent';
import ForgetPassword from './Containers/ForgetPassword';
import ForgetPasswordLink from './Containers/ForgetPasswordLink';
import BseOnboarding from './Containers/BseOnboarding';
import Bseonboardingstep2 from './Containers/BseOnboarding/bseonboardingstep2';
import Bseonboardingstep3 from './Containers/BseOnboarding/bseonboardingstep3';
import Bseonboardingstep4 from './Containers/BseOnboarding/bseonboardingstep4';
import Bseonboardingstep3cont from './Containers/BseOnboarding/bseonboardingstep3cont';
import Bseonboardingstep3forg from './Containers/BseOnboarding/bseonboardingstep3forg';
import ClientDetails from './Containers/BseOnboarding/clientdetails';
import Bseonboardingstep3contForg from './Containers/BseOnboarding/bseonboardingstep3contforg';
import Bseonboardingstep1minor from './Containers/BseOnboarding/bseonboardingstep1minor';
import Bsestep4guardiannominee from './Containers/BseOnboarding/bsestep4guardiannominee';
import BseAufForm from './Containers/BseOnboarding/BseAufForm';
import BankMandate from './Containers/BankMandate';
import Bank from './Containers/Bank';
import Order from './Containers/Order';
import Bseonboardingsignupload from "./Containers/BseOnboarding/bseonboardingstep4cont"
import HelpSupport from "./Containers/Helpsupport"
import Setting from './Containers/Settings';
import MutualFunds from './Containers/Mutualfunds';
import Investment from './Containers/Investment';
import Creategoals from './Containers/Creategoals';
import MutualFundsdetail from "./Containers/mutualdetails"
import Mysips from './Containers/Mysips';
import RiskManagement from './Containers/RiskAssestment';
// import Basicdetails from './Containers/Basic-details';
import Changepassword from './Containers/Changepassword';
import Detailsnominee from "./Containers/Detailsnominee"
import Goaldetails from "./Containers/Goaldetail/index"
import MObileLogin from "./Containers/Mobilelogin/index"
import Orderdetail from './Containers/Orderdetails';
import Notification from './Containers/notification';
import Myaccount from './Containers/myaccount';
import StartInvestment from './Containers/startInvestment';
import Investmentredeem from './Containers/investmentredeem';
import Investmentswitch from './Containers/investmentswitch';
import Portfoliodetails from './Containers/Portfoliodetail';
import Multiplefundsippage from './Containers/multiplefund_sippage';
import CreateGoal2 from './Containers/Creategoals/index2';
import BseOnboardingNomineeAuth from './Containers/BseOnboarding/bseonboardingnomineeauth';
import PopularBasket from './Containers/PopularBasket';
import PopuraBasketDetails from './Containers/PopularBasketDetails';
import DirectLogin from './Containers/DirectLogin';
import CompleteProfileNew from './Containers/CompleteProfile/completeprofilenew';
import InvestmentReport from './Containers/Investment/investmentreport';
import PortfolioValuationReport from './Containers/Investment/portfoliovaluationreport';
import TransactionStatus from './Containers/TransactionStatus';
import DeleteAccount from './Containers/DeleteAccount';
import SipOrderAccepted from './Containers/SipOrderAccepted';
import MinorCheckUpload from './Containers/MinorUploadCheck';
import CapitalGainStatement from './Containers/CapitalGainStatement';
import PreviousRequestList from './Containers/PreviousRequestList';
import StatementList from './Containers/StatementList';

function App() {
  return (
    <div className="App">
  
        <Router>
  <Routes>
    
  <Route exact path='/' activeClassName="active" element={ <Home /> }/>
  <Route exact path='/register' activeClassName="active" element={ <Register /> }/>
  <Route exact path='/dashboard' activeClassName="active" element={ <Dashboard /> }/>
  <Route exact path='/completeprofile' activeClassName="active" element={ <CompleteProfile /> }/>
  <Route exact path='/bankdetails' activeClassName="active" element={ <BankDetail /> }/>
  <Route exact path='/investorconcent' activeClassName="active" element={ <InvestorConcent /> }/>
  <Route exact path='/forgetpassword' activeClassName="active" element={ <ForgetPassword /> }/>
  <Route exact path='/resetpasswordlink' element={<ForgetPasswordLink/>} />
  <Route exact path='/bseonboarding' activeClassName="active" element={ <BseOnboarding /> }/>
  <Route exact path='/bseonboarding/:id' activeClassName="active" element={ <BseOnboarding /> }/>
  <Route exact path='/bseonboarding/step2' activeClassName="active" element={ <Bseonboardingstep2 /> }/>
  <Route exact path='/bseonboarding/step3' activeClassName="active" element={ <Bseonboardingstep3 /> }/>
  <Route exact path='/bseonboarding/step3cont' activeClassName="active" element={ <Bseonboardingstep3cont/> }/>
  <Route exact path='/bseonboarding/step3contforg' activeClassName="active" element={ <Bseonboardingstep3contForg/> }/>
  <Route exact path='/bseonboarding/step3forg' activeClassName="active" element={ <Bseonboardingstep3forg/> }/>
  <Route exact path='/bseonboarding/step4' activeClassName="active" element={ <Bseonboardingstep4 /> }/>
  <Route exact path='/bseonboarding/step4/upload_signature' activeClassName="active" element={ <Bseonboardingsignupload /> }/>
  <Route exact path='/bseonboarding/clientdetails' activeClassName="active" element={ <ClientDetails /> }/>
  <Route exact path='/bseonboarding/minor-details' activeClassName="active" element={ <Bseonboardingstep1minor /> }/>
  <Route exact path='/bseonboarding/guardian-nominee' activeClassName="active" element={ <Bsestep4guardiannominee /> }/>
  <Route exact path='/bseaufform' activeClassName="active" element={ <BseAufForm /> }/>
  <Route exact path='/bankmandate/:id' activeClassName="active" element={ <BankMandate /> }/>
  <Route exact path='/bank' activeClassName="active" element={ <Bank /> }/>
  <Route exact path='/order' activeClassName="active" element={ <Order /> }/>
  {/* <Route exact path='/create-goals' activeClassName="active" element={ <Creategoals /> }/> */}
  <Route exact path='/my-SIPs' activeClassName="active" element={ <Mysips /> }/>
  <Route exact path='/help-support' activeClassName="active" element={ <HelpSupport /> }/>
  <Route exact path='/setting' activeClassName="active" element={ <Setting /> }/>
  <Route exact path='/mutual-funds' activeClassName="active" element={ <MutualFunds /> }/>
  <Route exact path='/mutual-funds/:goalID' activeClassName="active" element={ <MutualFunds /> }/>
  <Route exact path='/investment' activeClassName="active" element={ <Investment /> }/>
  <Route exact path='/mutualfund-details/:id' activeClassName="active" element={ <MutualFundsdetail /> }/>
  <Route exact path='/mutualfund-details/:id/:goalID' activeClassName="active" element={ <MutualFundsdetail /> }/>
  <Route exact path='/order-details/:id' activeClassName="active" element={ <Orderdetail /> }/>
  <Route exact path='/goaldetails/:id' activeClassName="active" element={ <Goaldetails /> }/>
  <Route exact path='/risk-assessment' activeClassName="active" element={ <RiskManagement /> }/>
  {/* <Route exact path='/basic-details' activeClassName="active" element={ <Basicdetails /> }/> */}
  <Route exact path='/change-password' activeClassName="active" element={ <Changepassword /> }/>
  <Route exact path='/nominee-details' activeClassName="active" element={ <Detailsnominee /> }/>
  <Route exact path='/login-mobile' activeClassName="active" element={ <MObileLogin /> }/>

  <Route exact path='/notification' activeClassName="active" element={ <Notification /> }/>
  <Route exact path='/my-account' activeClassName="active" element={ <Myaccount /> }/>
  <Route exact path='/start-investment/:id' activeClassName="active" element={ <StartInvestment /> }/>
  <Route exact path='/investment-redeem/:id' activeClassName="active" element={ <Investmentredeem /> }/>
  <Route exact path='/investment-switch/:id' activeClassName="active" element={ <Investmentswitch /> }/>
  <Route exact path='/portfolio-details/:id' activeClassName="active" element={ <Portfoliodetails /> }/>
  <Route exact path='/portfolio-details/:id/:navval' activeClassName="active" element={ <Portfoliodetails /> }/>
  <Route exact path='/multiplefunds-SIP' activeClassName="active" element={ <Multiplefundsippage /> }/>
  <Route exact path='/create-goals' activeClassName="active" element={ <CreateGoal2 /> }/>
  <Route exact path='/bseonboarding/nomineeauth' activeClassName="active" element={ <BseOnboardingNomineeAuth/> }/>
  <Route exact path='/popular-basket' activeClassName="active" element={ <PopularBasket/> }/>
  <Route exact path='/popular-basket/:goalID' activeClassName="active" element={ <PopularBasket/> }/>

  <Route exact path='/popularbasketdetails/:id' activeClassName="active" element={ <PopuraBasketDetails/> }/>
  <Route exact path='/popularbasketdetails/:id/:goalID' activeClassName="active" element={ <PopuraBasketDetails/> }/>
  <Route exact path='/direct-login/:id' activeClassName="active" element={ <DirectLogin/> }/>
  {/* <Route exact path='/completeprofile' activeClassName="active" element={ <CompleteProfileNew/> }/> */}
<Route exact path='/investment-report' activeClassName = "active" element={<InvestmentReport/>}/>
<Route exact path='/portfolio-valuation-report' activeClassName = "active" element={<PortfolioValuationReport/>}/>
<Route exact path='/transaction-status/:id' activeClassName = "active" element={<TransactionStatus/>}/>
<Route exact path='/delete-account' activeClassName = "active" element={<DeleteAccount/>}/>
<Route exact path='/siporderaccepted/:id' activeClassName = "active" element={<SipOrderAccepted/>}/>
<Route exact path='/minor-check-upload' activeClassName = "active" element={<MinorCheckUpload/>}/>
<Route exact path='/capital-gain-statement' activeClassName = "active" element={<CapitalGainStatement/>}/>
<Route exact path='/previous-request' activeClassName = "active" element={<PreviousRequestList/>}/>
<Route exact path='/statement-list/:id' activeClassName = "active" element={<StatementList/>}/>

  {/* <Route exact path='/bseonboarding/step5' activeClassName="active" element={ <Bseonboardingstep5 /> }/> */}







  </Routes>
  </Router>

    
    </div>
  );
}

export default App;
