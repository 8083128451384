import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";

let apiServices = new ApiService();
function CapitalGainStatement() {
    const [step, setStep] = useState(1)
    const [userData, setUserData] = useState({})
    const [financialYear, setFinancialYear] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const [cgsId, setCgsId] = useState("")
    const [submitLoader , setSubmitLoader] = useState(false)
      const [loaderName, setLoaderName] = useState("")
    const [otp, setOtp] = useState("")
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getuserdata()
            console.log(getFinancialYearList())

        }
        didMountRef.current = false;
    }, []);

    const getuserdata = () => {
        apiServices.bsegetuserdataPostRequest({}).then(result => {
            if (result.data.status == 'success') {
                setUserData(result.data.data)
            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                setisLoading(false)
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
              }
        })
    }
    const submitstatementrequest = () => {
        setisLoading(true)
        const dataString ={
            FinancialYear : financialYear
        }
        apiServices.submitstatementrequestpostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                setisLoading(false)
                setCgsId(result.data.id)
                toast(result.data.message);
                 setStep(3)

            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                setisLoading(false)
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
                setStep(4)
              }
            else{
                setisLoading(false)
                toast.error(result.data.message);
            }
        })
    }

    const verifyotpinvestorconcent=()=>{
        if(otp == "" || otp == null){
            toast.error("Please Enter OTP");
            return
        }
        setisLoading(true)
        const dataString ={
            otp : otp,
            cgs_id : cgsId
        }
        apiServices.investorconcentpostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                setisLoading(false)
     
                setStep(4)
                setLoaderName("Fetch user capital gain statement data")
              
            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                setisLoading(false)
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
              }
            else{
                setisLoading(false)
                toast.error(result.data.message);
            }
        })
    }
  
    const handlechangeotp = (event) => {
        setOtp(event)
    }

    const getFinancialYearList = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // JavaScript months are 0-based

        // Determine the current financial year
        const currentFinancialYearStart = month < 4 ? year - 1 : year;

        // Generate the list of past two financial years including the current
        const financialYears = [
            `${currentFinancialYearStart - 2}-${currentFinancialYearStart - 1}`,
            `${currentFinancialYearStart - 1}-${currentFinancialYearStart}`,
        ];

        return financialYears;
    };

    const gotostep2=()=>{
        if(financialYear == "" || financialYear == null){
            toast.error("Please select financial year");
            return
        }
        setStep(2)
    }

    return (
        <>
              <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <Header />

            <BrowserView>
                <div className="auth-page-wrapper pt-50">
                    <div className="auth-page-content">
                    
                        {step == 1 ?
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="crds">
                                        <button className="btn btn-primary btn-sm float-end mt-2 me-2" onClick={()=>navigate('/previous-request')}>
                        Previous Request
                      </button>
                                            <div className="crds-body p-50">
                                            
                                                <div className="text-center mb-30">
                                            
                                                    <img src="/img/logo.png" className="wd-150 mb-30"></img>
                                                    
                     
                                                    <h5>Custom Gain Statement</h5>
                                                   
                                                </div>
                                                <div className="from-group mb-20">
                                                    <label> Financial Year</label>
                                                    <select name="financial_year"  onChange={(e)=>setFinancialYear(e.target.value)}>
                                                        <option>Select Year</option>
                                                        {getFinancialYearList().map((item, index) => (
                                                            <option key={index} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <button
                                                    className="btn btn-primary btn-lg btn-full mb-4"
                                                    onClick={() => gotostep2()}
                                                >

                                                    Continue

                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                        {step == 2 ?
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="crds">
                                            <div className="crds-body p-50">
                                                <div className="text-center mb-30">
                                                    <img src="/img/logo.png" className="wd-150 mb-30"></img>
                                                    <h5>Verify your contact details</h5>
                                                    <p>Confirm or modify your mobile number/email ID to import external Custom Gain Statement linked to your PAN</p>
                                                </div>
                                                <div className="from-group mb-20">
                                                    <label> PAN No.</label>
                                                    <input type="text" name="pan" value={userData.gs_pan_no} readOnly></input>
                                                </div>
                                                <div className="from-group mb-20">
                                                    <label> Mobile No.</label>
                                                    <input type="number" name="mobile" value={userData.gs_mobile} readOnly></input>
                                                </div>
                                                
                                              
                                                <div className="dflex">
                                                    <button
                                                        className="btn btn-outline-primary btn-lg btn-full mb-4 " onClick={() => setStep(1)}>
                                                        Cancel
                                                    </button>
                                                    <button
                        className="btn btn-primary btn-lg btn-full mb-4 ms-2"
                        onClick={() => submitstatementrequest()}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                            "Contine"
                        )}
                    </button>
                                                   

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                        {step == 3 ?
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="crds">
                                            <div className="crds-body p-50">
                                                <div className="text-center mb-30">
                                                    <img src="/img/logo.png" className="wd-150 mb-30"></img>
                                                    <h5>Confirm with OTP</h5>
                                                    <p>Verify the 6-digit OTP received on {userData.gs_mobile}</p>
                                                </div>
                                                <div className="from-group mb-20">
                                                    <label> OTP</label>
                                                    <input type="number" name="otp" onChange={(e) => handlechangeotp(e.target.value)}></input>
                                                </div>
                                                <div className="dflex">
                                                    <button
                                                        className="btn btn-outline-primary btn-lg btn-full mb-4 " onClick={() => setStep(1)}>
                                                        Cancel
                                                    </button>
                                                   
                                                    <button
                        className="btn btn-primary btn-lg btn-full mb-4 ms-2"
                        onClick={()=>verifyotpinvestorconcent()} 
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                            "Contine"
                        )}
                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                        {step == 4 ?
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="crds">
                                            <div className="crds-body p-50">
                                                <div className="text-center mb-30">
                                                    <img src="/img/logo.png" className="wd-150 mb-30"></img>
                                                    <h5>Importing Capital Gain Statement</h5>
                                                    <p>This could take some time. You can safely dismiss this.We'll notify you one your Capital Gain Statement is imported</p>
                                                </div>
                                              
<div className="text-center mb-4">
          <Hourglass
            visible
            ariaLabel="Loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#304AA7', '#304AA7']}
          />

        </div>

                                                <button
                                                    className="btn btn-primary btn-lg btn-full mb-4" onClick={()=>navigate('/dashboard')}>
                                                    Dismiss
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className="p25 mt-5">
                    {step == 1 ?
                        <div className="mb-4 text-center">
                            <img src="/img/logo.png" className="wd-150 mb-4"></img>
                            <h5>Custom Gain Statement</h5>
                            <div className="from-group mb-3">
                                <label> Financial Year</label>
                                <select name="financial_year" readOnly>
                                    <option>Select Year</option>
                                    {getFinancialYearList().map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                            <button
                                className="btn btn-primary btn-lg btn-full mb-4"
                                onClick={() => setStep(2)}
                            >

                                Continue

                            </button>
                        </div> : ""}
                    {step == 2 ?
                        <div className="mb-4 text-center">
                            <img src="/img/logo.png" className="wd-150 mb-4"></img>
                            <h5>Custom Gain Statement</h5>
                            <p>Confirm or modify your mobile number/email ID to import external Custom Gain Statement linked to your PAN</p>
                            <div className="from-group mb-20">
                                <label> Mobile No.</label>
                                <input type="number" name="mobile" value={userData.gs_mobile} readOnly></input>
                            </div>
                            <div className="from-group mb-20">
                                <label> Email ID</label>
                                <input type="email" name="email" value={userData.gs_email} readOnly></input>
                            </div>
                            <div className="from-group mb-20">
                                <label> PAN No.</label>
                                <input type="text" name="pan" value={userData.gs_pan_no} readOnly></input>
                            </div>
                            <div className="dflex">
                                <button
                                    className="btn btn-outline-primary btn-lg btn-full mb-4 " onClick={() => setStep(1)}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary btn-lg btn-full mb-4 ms-2" onClick={() => setStep(3)}>
                                    Contine
                                </button>

                            </div>
                        </div> : ""}
                    {step == 3 ?
                        <div className="mb-4 text-center">
                            <img src="/img/logo.png" className="wd-150 mb-4"></img>
                            <h5>Custom Gain Statement</h5>
                            <div className="from-group mb-3">
                                <label> OTP</label>
                                <input type="number" name="otp" onChange={(e) => handlechangeotp(e.target.value)}></input>
                            </div>
                            <div className="dflex">
                                <button
                                    className="btn btn-outline-primary btn-lg btn-full mb-4 " onClick={() => setStep(1)}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary btn-lg btn-full mb-4 ms-2" onClick={() => setStep(4)} disabled={!otp}>
                                    Contine
                                </button>
                            </div> 
                            </div>
                            :""}
                            {step == 4 ?
                                <div className="mb-4 text-center">
                                    <img src="/img/logo.png" className="wd-150 mb-4"></img>
                                    <h5>Importing External Portfolio</h5>
                                    <p>This could take some time. You can safely dismiss this.We'll notify you one your Custom Gain Statement is imported</p>
                                    <button
                                        className="btn btn-primary btn-lg btn-full mb-4"
                                       
                                    >

                                      Dismiss

                                    </button>
                                </div> : ""}


                        </div>
                </MobileView>
        </>
    )
}
export default CapitalGainStatement