import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";

let apiServices = new ApiService();
function StatementList(){
    const [statementList , setStatementList] = useState([])
    const didMountRef = useRef(true)
    const{id} = useParams()
    const navigate = useNavigate()
    useEffect(() => {
            if (didMountRef.current) {
                statementListData()
    
            }
            didMountRef.current = false;
        }, []);
        const statementListData = () => {
            const dataString = {
                cgs_id : id
            }
            apiServices.CGSStatementListpostrequest(dataString).then(result => {
                if (result.data.status == 'success') {
                    setStatementList(result.data.data)
                }
                else if (result.data.status === "error" && result.data.message === "Session expired") {
                    navigate("/");
                    localStorage.removeItem('AUTH_TOKEN');
                }
            })
        }
    return (
        <>
            <Header/>
<ul>
    {statementList.map((item, index) => (
        <li key={index}>{item.cgsd_amc_name}({item.cgsd_price})</li>))}
</ul>
            <Footer/>
        </>
    )
}
export default StatementList;