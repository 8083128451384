import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Skeleton from "react-loading-skeleton";
import { Breadcrumb } from "react-bootstrap";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";

let apiServices = new ApiService();
function PreviousRequestList() {
    const [cgsStatementData, setStatementData] = useState([])
    const [submitLoader, setSubmitLoader] = useState({})
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            statementData()

        }
        didMountRef.current = false;
    }, []);
    const statementData = () => {
        apiServices.CGSStatementRequestListpostrequest({}).then(result => {
            if (result.data.status == 'success') {
                setStatementData(result.data.data)
            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
            }
        })
    }

    const getStatementDocument=(cgsId)=>{

      setSubmitLoader(prevState => ({ ...prevState, [cgsId]: true }));
        const dataString ={
            cgs_id : cgsId
        }
        apiServices.getStatementDocumentpostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                statementData()
                setSubmitLoader(prevState => ({ ...prevState, [cgsId]: false }));
               
            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                setSubmitLoader(prevState => ({ ...prevState, [cgsId]: false }));
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
              }
            else{
                setSubmitLoader(prevState => ({ ...prevState, [cgsId]: false }));
                toast.error(result.data.message);
            }
        })
    }
    return (
        <>
            <Header />

            <section className="sec-pad-sm">
                <div className="container">
                    <div className="row g-3">

                        <div className="col-lg-12">

                            <div className="row g-2">
                                {cgsStatementData.map((item) => (
                                    <div className="col-lg-12">

                                        <div className="invbox">
                                            <div className="invboxfund">

                                                <div className="invboxfund-details" style={{ marginLeft: '0' }}>
                                                    <a href="#" className="tx-14">{item.cgs_client_ref_no}</a>

                                                </div>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Request ID</span>
                                                <span className="fw600">{item.cgs_req_id}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Financial Year</span>
                                                <span className="fw600">{item.cgs_start_financial_year}-{item.cgs_end_financial_year}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Request Date</span>
                                                <span className="fw600"> {moment(item.created_at).format('YYYY-MM-DD')}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Status</span>
                                                {item.cgs_statement_status == 1 ?
                                                    <span className="fw600"><span class="badge bg-warning-subtle text-warning">Requested</span></span>
                                                    : <span className="fw600"><span class="badge bg-success-subtle text-success">Process</span></span>}
                                            </div>
                                            <div className="invboxsec">
                                             {item.cgs_statement_status == 1 ?
                                            <span className="fw600" key={item.cgs_id}>
        <button
            className="btn btn-primary btn-sm float-end mt-2 me-2"
            onClick={() => getStatementDocument(item.cgs_id)}
            disabled={submitLoader[item.cgs_id]} // Disable button during loading
        >
            {submitLoader[item.cgs_id] ? (
                <img src="/img/loder01.gif" width="60px" height="11px" alt="Loading..." />
            ) : (
                "Check Status"
            )}
        </button>
    </span>
                                           : <span className="fw600" key={item.cgs_id}>
                                           <a href={"/statement-list/"+item.cgs_id} class="btn btn-outline-primary btn-sm mt-3 mb-0">View Statement <i class="d-icon-angle-right"></i></a>
        
    </span>}



                                            </div>
                                        </div>
                                    </div>))}


                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default PreviousRequestList