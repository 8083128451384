import React, { useState } from "react";
import { useRef } from "react";
import { BrowserView } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import { ApiService } from "../../Components/Services/apiServices";


let apiServices = new ApiService();
function MinorCheckUpload() {

    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);

    const handleButtonClick = () => {
        // Programmatically trigger the file input click
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/tiff', 'application/pdf'];
         
            if (!allowedTypes.includes(file.type)) {
              alert('Invalid file type. Only .tiff, .tif, or .pdf files are allowed.');
              return;
            }
            const reader = new FileReader();

            reader.onloadend = () => {
                setImage(reader.result);  // Set the image as a base64 string
                console.log(reader.result)
            };

            reader.readAsDataURL(file);  // Read the file as a base64-encoded string
        }
    };

    function uploadcheckimage() {
        const dataString = {
            checkimage: image
        }
        apiServices.chaqeimagefornriminorpostrequest(dataString).then(res => {

            console.log(res)
        })
    }


    return (
        <>
            <BrowserView>
                <ToastContainer position={toast.POSITION.TOP_CENTER} />

                <div className="auth-page-wrapper pt-50">
                    <div className="auth-page-content">
                        <div className="container">
                            <div className="row justify-content-center mt-3">
                                <div className="col-lg-6">
                                    <div className="crds">

                                        <div className="crds-body p-50">
                                            <div className="text-center mb-30">
                                                <img src="/img/logo.png" className="wd-150 mb-4"></img>

                                                <h6>Upload Copy of bank check(Minor)</h6>
                                            </div>
                                            <div className="loginbox" >
                                                <div className="mb-4">

                                                    <div className="col-lg-12">
                                                        <div className="from-group mb-3">
                                                            {image ?
                                                                <img
                                                                    src="/img/pdf.png"
                                                                    alt="Base64 Image"
                                                                    style={{ maxWidth: '100%', maxHeight: '200px', display: 'block',
    margin: '0 auto',
    position: 'relative',}}
                                                                /> : ''}

                                                            <div className="dflex mt-4">
                                                                <button className="btn btn-primary btn-full btn-md me-1" onClick={handleButtonClick}>Upload</button>
                                                                <input
                                                                    type="file"
                                                                    ref={fileInputRef}
                                                                    style={{ display: 'none' }}
                                                                    accept=".tiff,.tif,.pdf" 
                                                                    onChange={handleFileChange}
                                                                />
                                                                {image ?
                                                                    <button className="btn btn-primary btn-full btn-md me-1" onClick={uploadcheckimage}>Submit</button> : ''}
                                                            </div>
                                                        </div>
                                                    </div>




                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>

        </>
    )
}
export default MinorCheckUpload