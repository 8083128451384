import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "swiper/css/navigation";
import 'swiper/css';
import { ToastContainer, toast } from 'react-toastify';
import CustomcircularProgressBar from "../../Components/Elements/circular_process_bar";
import { BarChart } from '@mui/x-charts/BarChart';
import { Chart } from "react-google-charts";
import moment from 'moment';


import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import BottomMenu from "../../Components/Footer-Bottom-menu";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function Dashboard() {
    const [mutualfunds, setmutualfunds] = useState([])
    const [UserData, setUserData] = useState("")
    const [totalCurrentValue, setTotalCurrentValue] = useState("")
    const [imgUrl, setimgUrl] = useState("")
    const [totalReturn, setTotalReturn] = useState({})
    const [graphDashboardData, setGraphDashboardData] = useState([])
    const [showGraphSection, setShowGraphSection] = useState(false)
    const [showRiskProfileGraph, setShowRiskProfileGraph] = useState()
    const [showPortfolioData , setShowPortfolioData] = useState(false)
    const [popularBasketData, setPopularBasketData] = useState([]);
    const [popularBasketBaseUrl, setPopularBasketBaseUrl] = useState("")
    const [ubdtStatusText, setUbdtStatusText] = useState("We are checking your KYC status please wait for 4-5 minutes")
    const [ubdtStatus, setUbdtStatus] = useState("")
    const [ubdtStatusClass, setUbdtStatusClass] = useState("")
    const [kycStatus, setKycStatus] = useState("")
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    let apiServices = new ApiService();
    const sliderRef = useRef(null);
    useEffect(() => {
        if (didMountRef.current) {
            console.log(totalReturn)
            showGraph()
            apiServices.getpopularmutualfunds().then(result => {

                if (result.data.status == 'success') {
                    let data = result.data.data
                    setmutualfunds(data)
                    setimgUrl(result?.data?.imgeURL)
                } else if (result.data.status == 'error') {
                    toast.error(result.data.status)
                }
            })
            // apiServices.gettotalgoalstoxportfoliopostrequest({}).then(result => {
            //     if (result.data.status == "success") {
            //         setTotalCurrentValue(result.data.totalReturn)

            //         setTotalReturn(result.data.totalReturn)
            //     }

            // })
            // apiServices.gettotalgoalstoxportfoliopostrequest({}).then(result => {
            //     if (result.data.status == "success") {
            //         setTotalCurrentValue(result.data.totalReturn)

            //         setTotalReturn(result.data.totalReturn)
            //     }

            // })
            setShowPortfolioData(true)
            apiServices.getgoalstoxholdingsashboardver2postrequest({}).then(result => {
                if (result.data.status == "success") {
                    setShowPortfolioData(false)
                    setTotalCurrentValue(result.data.HeaddingData)

                    setTotalReturn(result.data.totalReturn)
                }
                else{
                    setShowPortfolioData(false)
                }

            })
            setShowRiskProfileGraph(true)
            getuserdata()
            apiServices.popularbasketfeaturedlistingpostrequest().then(result => {
                if (result.data.status == "success") {
                    setPopularBasketData(result.data.data)
                    setPopularBasketBaseUrl(result.data.imgeURL)
                }

            })


        }
        didMountRef.current = false;
    }, []);

    const getuserdata = () => {
        apiServices.bsegetuserdataPostRequest({}).then(result => {
            if (result.data.status == 'success') {
                setUserData(result.data.data)
                setUbdtStatus(result.data.data.gs_ubdt_status)
                setKycStatus(result.data.data.gs_kyc_status)
                if (result.data.data.gs_kyc_status == 0 || result.data.data.gs_kyc_status == 4) {
                    setUbdtStatusText("Mutual fund KYC is required to be done")
                    setUbdtStatusClass("alert alert-warning")
                }
                else {
                    console.log("dddeeesss")
                    setUbdtStatusText("else")
                    setUbdtStatusClass("alert alert-warning")
                }
                //  else if(result.data.data.gs_kyc_status == 2){
                //     setUbdtStatusText("We are unable to process your request")
                //     setUbdtStatusClass("alert alert-warning")
                //  }

                if (result.data.data.gs_ubdt_status == '01') {
                    setUbdtStatusText("Mutual Fund KYC under process")
                    setUbdtStatusClass("alert alert-warning")
                }

                else if (result.data.data.gs_ubdt_status == '02') {

                    setUbdtStatusText("Mutual fund KYC Update needed")
                    setUbdtStatusClass("alert alert-danger")
                }
                else if (result.data.data.gs_ubdt_status == '03') {
                    setUbdtStatusText("Mutual Fund KYC is on Hold")
                    setUbdtStatusClass("alert alert-danger")
                }
                else if (result.data.data.gs_ubdt_status == '04') {
                    setUbdtStatusText("Mutual Fund KYC is rejected Status")
                    setUbdtStatusClass("alert alert-danger")
                }
                else if (result.data.data.gs_ubdt_status == '05' || result.data.data.gs_ubdt_status == '06') {
                    setUbdtStatusText("KYC is required to be done")
                    setUbdtStatusClass("alert alert-warning")
                }
                else if (result.data.data.gs_ubdt_status == '07') {
                    setUbdtStatusText("validated")
                    setUbdtStatusClass("alert alert-success")
                }
                else {
                    console.log("dddeeesss")
                    setUbdtStatusText("else")
                    setUbdtStatusClass("alert alert-warning")
                }


                setShowRiskProfileGraph(false)
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                navigate("/")
            }
            else {
                alert(result.data.message)
                setShowRiskProfileGraph(false)
            }
        })
    }

    const navigatetoportfolio = () => {
        navigate('/investment')
    }
    const showGraph = () => {
        apiServices.dashboradgraohdatapostrequest({}).then(result => {
            if (result.data.status == "success") {
                setGraphDashboardData(result.data.data)
                setShowGraphSection(true)
            }
        })
    }
    const datasets = [];
    graphDashboardData.forEach((productName, index) => {
        datasets.push({
            label: "Goal",
            data: [productName.goal_monthly_investment_rounded_off * 12 * productName.goal_number_of_year],
            backgroundColor: index % 2 === 0 ? 'rgba(142, 86, 255, 0.4)' : 'rgba(231, 17, 131, 0.2)'
        });
    })
    const totalGoalCalculate = (monthly_investment, goal_years) => {
        return Number(monthly_investment) * 12 * Number(goal_years)
    }
    const difference_Goalyearss = (created_date, monthly_investment) => {
        const createdDate = new Date(created_date);
        const today = new Date();
        const diffInMilliseconds = today - createdDate;
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
        if (diffInDays >= 30) {
            const yearDiff = today.getFullYear() - createdDate.getFullYear();
            const monthDiff = today.getMonth() - createdDate.getMonth();
            const monthsElapsed = yearDiff * 12 + monthDiff;
            const multipliedAmount = monthly_investment * monthsElapsed;
            return multipliedAmount;
        } else {
            return 0;
        }
    };

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);
    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, [])

    const options = {
        // title: "Density of Precious Metals, in g/cm^3",
        width: '100%',
        height: 200,
        bar: { groupWidth: "50%" },
        legend: { position: "none" },
        hAxis: {
            slantedText: false,
            textPosition: 'out',
            gridlines: {
                color: 'transparent'
            },
            baselineColor: 'black',
            titleTextStyle: {
                color: '#333'
            }
        },

        // Remove grid lines
        vAxis: {
            gridlines: {
                color: 'transparent'
            },
            baselineColor: 'black'
        }
    };

    const KYCprocess = (e) => {
        let applicationtype = ""
        if (ubdtStatus == '02' || ubdtStatus == '03' || ubdtStatus == '05' || ubdtStatus == '06') {
            applicationtype = "modify"
        }
        else if (ubdtStatus == '04') {
            applicationtype = "new"
        }
        const dataString = {
            applicationtype: applicationtype
        }
        apiServices.kycLoginpostrequest(dataString)
            .then(result => {
                console.log(result, "dfgdfgdfg");
                if (result?.data?.status === "success") {

                    const url = result?.data?.redirectlink;
                    console.log(result?.data?.redirectlink);
                    window.open(url, '_blank');
                } else {
                    toast(result.data.message);

                }
            })
            .catch(error => {

                console.log(error);
            });

    };
    const  loanapplication=async()=>{
        console.log("ddd")
        if (window.ScLoan) {
            const lasSdkInstance = new window.ScLoan({
              // Gateway name integration key is shared by the business team
              gatewayName: "goalstox",
            });
            try {
                const response = await lasSdkInstance.apply({
                  interactionToken: "INT6756d6f283245f840930497a",
                });
                // Handle success response
                console.log("Application successful:", response);
              } catch (e) {
                // Handle error
                console.error("Application failed:", e);
              }
            console.log("LAS SDK instance initialized:", lasSdkInstance);
          } else {
            console.error("ScLoan is not available in the global scope.");
          }
    }
    return (
        <>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <BrowserView>
                <Header />
                <section className="sec-pad-md">
                    <div className="container">
                        {/* {ubdtStatusText ? (
                            <>
                                <div className={ubdtStatusClass}>
                                    <div style={{ fontSize: "13px" }}>
                                        {ubdtStatusText !== "validated" ? (
                                            ubdtStatusText === "else" ? (
                                                <>
                                                    <div className="mb-2">
                                                        We are checking your KYC status please wait for 4-5 minutes.
                                                        <span onClick={getuserdata} style={{ cursor: "pointer", marginLeft: "937px" }}>
                                                            <strong>Refresh Status</strong><img src="/img/refresh.jpg" style={{ height: "15px", width: "15px", marginLeft: "5px" }} />
                                                        </span>
                                                    </div>

                                                </>
                                            ) : (
                                                <>

                                                    {(ubdtStatus === '01' || ubdtStatus === '02' || ubdtStatus === '03' || ubdtStatus === '04' || ubdtStatus === '05' || ubdtStatus === '06' || kycStatus === 1 || kycStatus === 4) && (<>
                                                        <div className="mb-2" >
                                                            Investment is restricted due to {ubdtStatusText}. Please contact us at support@goalstox.com or via WhatsApp at +91 97030 71249.
                                                        </div>
                                                        <span onClick={KYCprocess} style={{ cursor: "pointer" }}>
                                                            <strong>Do KYC</strong> <i className="fas fa-arrow-alt-circle-right"></i>
                                                        </span></>
                                                    )}
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <div className="mb-2">
                                                    Your KYC is validated.
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </>
                        ) : null} */}

                        {ubdtStatusClass ? (
                            <>
                                <div className={ubdtStatusClass}>
                                    <div style={{ fontSize: "13px" }}>
                                        {ubdtStatusText !== "validated" ? 
                                          
<>                                                 {ubdtStatusText == 'else' ?
                                                        <div className="mb-2" >
                                                              Checking your KYC status with KRAs. It will be updated as soon as we receive a response
                                                        </div>
                                                         : 
                                                         <div className="mb-2" >
                                                            Investment is restricted due to {ubdtStatusText}. Please contact us at support@goalstox.com or via WhatsApp at +91 97030 71249.
                                                        </div>
                                                       }
                                                        <span onClick={KYCprocess} style={{ cursor: "pointer" }}>
                                                            {/* <strong>Do KYC</strong> <i className="fas fa-arrow-alt-circle-right"></i> */}
                                                        </span>
                                               
                                            </>
                                        : 
                                            <>
                                                <div className="mb-2">
                                                    Your KYC is validated.
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            </>
                        ) : null}
{/* <div onClick={loanapplication} style={{cursor:"pointer"}}>Loan Application</div> */}
                        <div className="row">

                            <div className="col-lg-8">

                                <div className="row g-3 mb-4">


                                    <div className="col-lg-12 col-12">

                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <div className="quickbox color-primary-style" onClick={() => { navigate("/create-goals") }}>
                                            <h6 className="mb-1 tx-14">Set Goal</h6>
                                            <p className="mb-0 tx-12 tx-gray">Every financial goal can be achieved through disciplined approach.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <div className="quickbox color-extra02-style" onClick={() => { navigate("/risk-assessment") }}>
                                            <h6 className="mb-1 tx-14">Risk Profile</h6>
                                            <p className="mb-0 tx-12 tx-gray">A risk profile identifies the level of risk an individual is prepared and able to accept.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <div className="quickbox color-extra03-style" onClick={() => { navigate("/mutual-funds") }}>
                                            <h6 className="mb-1 tx-14">Start a SIP</h6>
                                            <p className="mb-0 tx-12 tx-gray">SIPs offer a disciplined and convenient way for investors to build wealth gradually.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <div className="quickbox color-tertiary-style" onClick={() => { navigate("/mutual-funds") }}>
                                            <h6 className="mb-1 tx-14"> Investment</h6>
                                            <p className="mb-0 tx-12 tx-gray">An investment is an asset or item acquired to generate income or gain appreciation.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-2 mb-4">
                                    <div className="col-lg-12 ">
                                        <div className="page-title mb-2">
                                            <h6 className="mb-0">Popular Funds</h6>
                                            <div className='swiper-buttons__box sbbRight'>
                                                <div className="sbbRight-prev" style={{ alignItems: 'center' }} onClick={handlePrev} >
                                                    <i className="d-icon-angle-left"></i>
                                                </div>
                                                <div className="sbbRight-next" onClick={handleNext} style={{ alignItems: 'center' }}>
                                                    <i className="d-icon-angle-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="funslider">
                                            <Swiper
                                                slidesPerView={3}
                                                spaceBetween={10}
                                                ref={sliderRef}
                                            >
                                                {mutualfunds.length > 0 ? <>
                                                    {mutualfunds?.map((items) => {
                                                        return (<>
                                                            <SwiperSlide>
                                                                <div className="popfunds" style={{ cursor: "pointer" }} onClick={() => { navigate(`/mutualfund-details/${items?.mf_id}`) }}>
                                                                    <div className="popfunds-icon mb-2">

                                                                        {items?.mf_image ? <><img src={items?.mf_image ? imgUrl + items?.mf_image : "/img/defaltimage.png"} /></> : <Skeleton width="" />}
                                                                    </div>
                                                                    {items?.mf_scheme_name ? <> <div className="popfunds-title">{items?.mf_scheme_name
                                                                    }</div></> : <Skeleton width="" />}
                                                                </div>
                                                            </SwiperSlide>
                                                        </>)
                                                    })}
                                                </> :
                                                    <>
                                                        <SwiperSlide>
                                                            <Skeleton height="150px">
                                                            </Skeleton>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <Skeleton height="150px">
                                                            </Skeleton>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <Skeleton height="150px">
                                                            </Skeleton>
                                                        </SwiperSlide>
                                                    </>
                                                }
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-2 mb-4">
                                    <div className="col-lg-12 ">
                                        <div className="page-title mb-2">
                                            <h6 className="mb-0">Popular Basket</h6>
                                            <div className='swiper-buttons__box sbbRight'>
                                                <div className="sbbRight-prev" style={{ alignItems: 'center', cursor: "pointer" }} onClick={() => { navigate('/popular-basket') }} >
                                                    See All <i class="d-icon-angle-right"></i>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="funslider">
                                            <Swiper
                                                slidesPerView={3}
                                                spaceBetween={10}
                                            // ref={sliderRef}
                                            >
                                                {popularBasketData.length > 0 ? <>
                                                    {popularBasketData?.map((items) => {
                                                        return (<>
                                                            <SwiperSlide>
                                                                <div className="popfunds" >
                                                                    <div className="popfunds-icon mb-2">

                                                                        <><img src={items?.pbgs_media != null ? popularBasketBaseUrl + items?.pbgs_media : "/img/defaltimage.png"} /></>
                                                                    </div>
                                                                    {items?.pbgs_basket_name ? <> <div className="popfunds-title">{items?.pbgs_basket_name
                                                                    }</div></> : <Skeleton width="" />}
                                                                    <a href={`/popularbasketdetails/${items?.pbgs_id}`} class="btn btn-outline-primary btn-sm mt-3 mb-0">Invest Now <i class="d-icon-angle-right"></i></a>
                                                                </div>
                                                            </SwiperSlide>
                                                        </>)
                                                    })}
                                                </> :
                                                    <>
                                                        <SwiperSlide>
                                                            <Skeleton height="150px">
                                                            </Skeleton>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <Skeleton height="150px">
                                                            </Skeleton>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <Skeleton height="150px">
                                                            </Skeleton>
                                                        </SwiperSlide>
                                                    </>
                                                }
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {graphDashboardData.length > 0 ?
                                            graphDashboardData.map((value) => (
                                                <div className="goalsection mb-4">
                                                    <div className="goalsection-header mb-2">
                                                        <div>
                                                            <h5 className="mb-1 fw600">Goal: {value.goal_type.charAt(0).toUpperCase() + value.goal_type.slice(1).toLowerCase()} Goal for {value.goal_name}</h5>
                                                            <p className="mb-0 tx-gray tx-12">Goal End Date: {moment(value.created_at).add(value.goal_number_of_year, 'years').format('DD-MMM-YYYY')}</p>
                                                        </div>
                                                        <div>
                                                            <a className="btn btn-outline-primary btn-sm me-2" href={"/popular-basket/" + value.goal_id}>Invest In Any Of Popular Basket</a>
                                                            <a className="btn btn-outline-primary btn-sm" href={'/mutual-funds/' + value.goal_id}>Invest On Your Own</a>
                                                        </div>
                                                    </div>

                                                    <div className="goalsection-body">
                                                        <div className="row g-3">
                                                            <div className="col-lg-6">
                                                                <div className="goalsection-tcontent">
                                                                    <div style={{ width: '100%' }}>
                                                                        <div className="text-center" style={{ width: '100px', margin: '0 auto' }}>

                                                                            <CustomcircularProgressBar percent={((difference_Goalyearss(value.created_at, value?.goal_monthly_investment_rounded_off) / totalGoalCalculate(value?.goal_monthly_investment_rounded_off, value?.goal_number_of_year)) * 100).toFixed(2)} color="#324dad" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="dflex align-items-center justify-content-between">
                                                                        <div className="text-center">
                                                                            <p className="mb-0 tx-gray tx-13">Goal</p>
                                                                            <h5 className="fw700 mb-0">{value.goal_type == "RETIREMENT" ? value.goal_corpus_needed : value.goal_actual_fund}</h5>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <p className="mb-0 tx-gray tx-13">Achieved</p>
                                                                            <h5 className="fw700 mb-0">-</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="goalsection-tcontent" style={{ padding: '0px' }}>

                                                                    <Chart
                                                                        chartType="BarChart"
                                                                        data={[
                                                                            [
                                                                                "Element",
                                                                                "Investment",
                                                                                { role: "style" },
                                                                                {
                                                                                    sourceColumn: 0,
                                                                                    role: "annotation",
                                                                                    type: "string",
                                                                                    calc: "stringify",
                                                                                },
                                                                            ],
                                                                            ["Planned", totalGoalCalculate(value?.goal_monthly_investment_rounded_off, value?.goal_number_of_year), "#324dad", null],
                                                                            ["Actual", difference_Goalyearss(value.created_at, value?.goal_monthly_investment_rounded_off), "#324dad", null],
                                                                        ]}
                                                                        options={options}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="goalsection-tcontent">
                                                                    <div className="gdlist">
                                                                        <ul>
                                                                            <li>
                                                                                <div className="gdbox">
                                                                                    <div className="gdbox-icon" ><img src="/img/g02.png" ></img></div>
                                                                                    <div className="gdbox-content">
                                                                                        <p className="mb-0 tx-12 tx-gray">Start Date</p>
                                                                                        <h6 className="mb-0 fw700">{moment(value.created_at).format('DD/MM/YYYY')}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="gdbox">
                                                                                    <div className="gdbox-icon"><img src="/img/g03.png" ></img></div>
                                                                                    <div className="gdbox-content">
                                                                                        <p className="mb-0 tx-12 tx-gray">End Date</p>
                                                                                        <h6 className="mb-0 fw700"> {moment(value.created_at).add(value.goal_number_of_year, 'years').format('DD/MM/YYYY')}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="gdbox">
                                                                                    <div className="gdbox-icon"><img src="/img/g01.png" ></img></div>
                                                                                    <div className="gdbox-content">
                                                                                        <p className="mb-0 tx-12 tx-gray">Goal Amount</p>
                                                                                        <h6 className="mb-0 fw700">{value.goal_type == "RETIREMENT" ? value.goal_corpus_needed : value.goal_actual_fund}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="gdbox">
                                                                                    <div className="gdbox-icon"><img src="/img/g04.png" ></img></div>
                                                                                    <div className="gdbox-content">
                                                                                        <p className="mb-0 tx-12 tx-gray">Live</p>
                                                                                        <h6 className="mb-0 fw700">{value.goal_is_live == 1 ? "Yes" : "NO"}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <p>Assumptions</p>
                                                                    <div className="asulist">
                                                                        <ul>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">SIP Req.</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>{value.goal_monthly_investment_rounded_off}</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">Lumsum Req.</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>{value.goal_lumpsum_value}</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">Inflation</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>{value.goal_expected_inflation}%</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">Return</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>{value.goal_expected_return}%</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">Mortality</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>{value.goal_type == "RETIREMENT" ? value.goal_mortality_age : "-"}</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">CAGR</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>-</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="asubox">
                                                                                    <p className="mb-0 tx-gray tx-12">XIRP</p>
                                                                                    <div className="asubox-inner">
                                                                                        <h4>-</h4>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)) : ""}
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-4">
                                <div className="panel mb-3 poniter">
                                    <div className="panel-header"><h6 className="mb-0">Your Investments</h6></div>
                                    <div className="panel-body" onClick={navigatetoportfolio}>
                                        {UserData?.gs_total_investment == 0 ? <><a href="/start-investment" className="btn btn-outline-primary btn-md mt-2 mb-0">Start Investment</a></> : <>
                                            <div className="dflex align-items-center" >
                                                <div className="mb-4">

                                                    <p className="mb-0 tx-gray tx-12">Current</p>

                                                    {showPortfolioData == false ?
                                                       
                                                        <h5 className="mb-0">₹{totalCurrentValue.totalMarketvALUE !== "" ? totalCurrentValue.totalMarketvALUE : 0}</h5>
                                                        
                                                        :<Skeleton width="55px" height="10px" />}
                                                </div>

                                                <div className="ml-auto text-right mb-4">
                                                    <p className="mb-0 tx-gray tx-12">Invested</p>
                                                    { showPortfolioData == false 
                                                        ?
                                                        <h5 className="mb-0">₹{totalCurrentValue.totalAmountInvested !== "" ? totalCurrentValue.totalAmountInvested : 0}</h5>
                                                        : <Skeleton width="55px" height="10px" />}

                                                </div>
                                            </div>
                                            <div className="dflex align-items-center" >
                                                <div>
                                                    <p className="mb-0 tx-gray tx-12">Returns</p>
                                                    { showPortfolioData == false 
                                                        ?


                                                        <h5 className={parseFloat(totalCurrentValue.return) >= 0 ? "mb-0 tx-green" : "mb-0 tx-red"}> {Math.sign(parseFloat(totalCurrentValue.return)) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true" style={{ fontSize: '11px' }}></i> : <i className="fa fa-arrow-up" aria-hidden="true" style={{ fontSize: '11px' }}></i>}
                                                            ₹{totalCurrentValue.return !== "" ? totalCurrentValue.return : 0}</h5>
                                                        : <Skeleton width="55px" height="10px" />}

                                                </div>

                                                <div className="ml-auto text-right">
                                                    <p className="mb-0 tx-gray tx-12">Total Return (%)</p>
                                                    { showPortfolioData == false 
                                                        ?
                                                        <h5 className={totalCurrentValue.totalabsolutereturns >= 0 ? "mb-0 tx-green" : "mb-0 tx-red"}>
                                                            {Math.sign(totalCurrentValue.totalabsolutereturns) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true" style={{ fontSize: '11px' }}></i> : <i className="fa fa-arrow-up" aria-hidden="true" style={{ fontSize: '11px' }}></i>}{totalCurrentValue.totalabsolutereturns !== "" ? totalCurrentValue.totalabsolutereturns : 0}%</h5>
                                                        : <Skeleton width="55px" height="10px" />}

                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                                {showRiskProfileGraph == false ?
                                    <div className="panel mb-3">
                                        <div className="panel-body text-center p-5">
                                            <h6>Your Risk Profile</h6>
                                            {UserData?.gs_risk_assestment_status == 1 ? <><h6 className="tx-theme mb-4"><span>{UserData?.gs_risk_assestment_profile}</span> ({UserData?.gs_risk_assestment_score}) </h6></> : ""}
                                            {UserData?.gs_risk_assestment_status == 1 ? <><CustomcircularProgressBar percent={UserData?.gs_risk_assestment_score} height="150px" width="150px" /></> : <img src="img/holdings.svg" className="wd-150"></img>}
                                            <p className="mt-1">Record your findings in a Risk Assessment and management plan, and implement them. </p>
                                            {UserData?.gs_risk_assestment_status == 0 ? <><a href="/risk-assessment" className="btn btn-outline-primary btn-md mt-2 mb-0">Risk assessment</a></> : ""}
                                        </div>
                                    </div> : ""}
                                {showRiskProfileGraph == true ?
                                    <div className="panel mb-3">
                                        <div className="panel-body text-center p-5">
                                            <Skeleton width="150px" height="10px" />
                                            <Skeleton width="150px" height="150px" borderRadius="70px" />
                                            <Skeleton width="200px" height="10px" />
                                            <Skeleton width="200px" height="10px" />
                                            <Skeleton width="200px" height="10px" />
                                        </div>
                                    </div> : ""}

                                <div className="panel mb-3">
                                    <div className="panel-body text-center">
                                        <img src="img/holdings.svg" className="wd-150"></img>
                                        <p>Add goals & start investing if you can plan yourself and only want to help us in tracking. </p>
                                        <a href="/create-goals" className="btn btn-outline-primary btn-md mb-5">Create a New Goal</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <Header />
                <div className="mobile-main">
                    <div className="pt-3 pb-5 mb-5">
                        <div className="container">
                            <div className="row g-2 mb-4">
                                <div className="col-lg-12 ">


                                    {/* {ubdtStatusText ? (
                                        <>
                                            <div className={ubdtStatusClass}>
                                                <div style={{ fontSize: "13px" }}>
                                                    <div style={{ marginBottom: "-10px" }}>
                                                        {ubdtStatusText !== "validated" ? (
                                                            ubdtStatusText === "else" ? (
                                                                <>

                                                                    We are checking your KYC status please wait for 4-5 minutes.
                                                                    <span onClick={getuserdata} style={{ cursor: "pointer", marginLeft: "4px" }}>
                                                                        <strong>Refresh Status</strong><img src="/img/refresh.jpg" style={{ height: "15px", width: "15px", marginLeft: "5px" }} />
                                                                    </span>



                                                                </>
                                                            ) : (
                                                                <>

                                                                    {(ubdtStatus === '02' || ubdtStatus === '03' || ubdtStatus === '04' || ubdtStatus === '05' || ubdtStatus === '06' || kycStatus === 1 || kycStatus === 4) && (<>
                                                                        <div className="mb-2" >
                                                                            Investment is restricted due to {ubdtStatusText}. Please contact us at support@goalstox.com or via WhatsApp at +91 97030 71249.
                                                                        </div>
                                                                        <span onClick={KYCprocess} style={{ cursor: "pointer" }}>
                                                                            <strong>Do KYC</strong> <i className="fas fa-arrow-alt-circle-right"></i>
                                                                        </span>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )
                                                        ) : (
                                                            <>

                                                                Your KYC is validated.

                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null} */}

                                    
                                    {ubdtStatusClass ? (
                                        <>
                                            <div className={ubdtStatusClass}>
                                                <div style={{ fontSize: "13px" }}>
                                                    <div style={{ marginBottom: "-10px" }}>
                                                        {ubdtStatusText !== "validated" ? 
                                                            <div className="mb-2" >
                                                                            Investment is restricted due to {ubdtStatusText}. Please contact us at support@goalstox.com or via WhatsApp at +91 97030 71249.
                                                                        </div> : 
                                                            <>

                                                                Your KYC is validated.

                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}



                                </div>
                                <div className="col-lg-12">
                                    <div className="panel mb-2 poniter">
                                        <div className="panel-header"><h6 className="mb-0">Your Investments</h6></div>
                                        <div className="panel-body" onClick={navigatetoportfolio}>
                                            {/* <div className="d-flex justify-content-center">
<a href={"/start-investment/"+"3948498"} className="btn btn-outline-primary btn-md mt-2 mb-0">Start Investment <i class="icon-arrow-right8 ms-2"></i></a>
</div> */}
                                            <div className="dflex align-items-center" >
                                                {/* <div >
                                                    {totalReturn !== null ?
                                                        // <h6 className={totalReturn >= 0 ? "mb-0 tx-green" : "mb-0 tx-red"} >  {totalReturn !== 0 ? `${totalReturn >= 0 ? '+' : '-'} ₹${Math.abs(totalReturn)}` : '₹0'}
                                                        // </h6>
                                                        <h6>₹{totalReturn.totalportfolio !== "" ? totalReturn.totalportfolio : 0}</h6>
                                                        : <Skeleton width="55px" height="10px" />}
                                                    <p className="mb-0 tx-gray tx-12">Total Portfolio</p>
                                                </div> */}


                                                <div className="mb-4">

                                                    <p className="mb-0 tx-gray tx-12">Current</p>
                                                    { showPortfolioData == false 
                                                        ?
                                                        <h5 className="mb-0">₹{totalCurrentValue.totalMarketvALUE !== "" ? totalCurrentValue.totalMarketvALUE : 0}</h5>
                                                        : <Skeleton width="55px" height="10px" />}
                                                </div>

                                                <div className="ml-auto text-right mb-4">
                                                    <p className="mb-0 tx-gray tx-12">Invested</p>
                                                    { showPortfolioData == false 
                                                        ?
                                                        <h5 className="mb-0">₹{totalCurrentValue.totalAmountInvested !== "" ? totalCurrentValue.totalAmountInvested : 0}</h5>
                                                        : <Skeleton width="55px" height="10px" />}

                                                </div>
                                            </div>
                                            <div className="dflex align-items-center" >
                                                <div>
                                                    <p className="mb-0 tx-gray tx-12">Returns</p>
                                                    { showPortfolioData == false 
                                                        ?


                                                        <h5 className={parseFloat(totalCurrentValue.return) >= 0 ? "mb-0 tx-green" : "mb-0 tx-red"}> {Math.sign(totalCurrentValue.return) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true" style={{ fontSize: '11px' }}></i> : <i className="fa fa-arrow-up" aria-hidden="true" style={{ fontSize: '11px' }}></i>}
                                                            ₹{totalCurrentValue.return !== "" ? totalCurrentValue.return : 0}</h5>
                                                        : <Skeleton width="55px" height="10px" />}

                                                </div>

                                                <div className="ml-auto text-right">
                                                    <p className="mb-0 tx-gray tx-12">Total Return (%)</p>
                                                    { showPortfolioData == false 
                                                        ?
                                                        <h5 className={totalCurrentValue.totalabsolutereturns >= 0 ? "mb-0 tx-green" : "mb-0 tx-red"}>
                                                            {Math.sign(totalCurrentValue.totalabsolutereturns) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true" style={{ fontSize: '11px' }}></i> : <i className="fa fa-arrow-up" aria-hidden="true" style={{ fontSize: '11px' }}></i>}{totalCurrentValue.totalabsolutereturns !== "" ? totalCurrentValue.totalabsolutereturns : 0}%</h5>
                                                        : <Skeleton width="55px" height="10px" />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="quickbox color-primary-style" onClick={() => { navigate("/create-goals") }}>
                                        <h6 className="mb-1 tx-14">Set Goal</h6>
                                        <p className="mb-0 tx-12 tx-gray">Every financial goal can be achieved through disciplined approach.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="quickbox color-extra02-style" onClick={() => { navigate("/risk-assessment") }}>
                                        <h6 className="mb-1 tx-14">Risk Profile</h6>
                                        <p className="mb-0 tx-12 tx-gray">A risk profile identifies the level of risk an individual is prepared and able to accept.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="quickbox color-extra03-style" onClick={() => { navigate("/mutual-funds") }}>
                                        <h6 className="mb-1 tx-14">Start a SIP</h6>
                                        <p className="mb-0 tx-12 tx-gray">SIPs offer a disciplined and convenient way for investors to build wealth gradually.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="quickbox color-tertiary-style" onClick={() => { navigate("/mutual-funds") }}>
                                        <h6 className="mb-1 tx-14">Lumpsum Investment</h6>
                                        <p className="mb-0 tx-12 tx-gray">An investment is an asset or item acquired to generate income or gain appreciation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-lg-12 ">
                                    <div className="page-title mb-2"><h6 className="mb-0">Popular Funds</h6>
                                        <div className='swiper-buttons__box sbbRight'>
                                            <div className="sbbRight-prev" style={{ alignItems: 'center' }} onClick={handlePrev} >
                                                <i className="d-icon-angle-left"></i>
                                            </div>
                                            <div className="sbbRight-next" onClick={handleNext} style={{ alignItems: 'center' }}>
                                                <i className="d-icon-angle-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="fundslider mb-2">
                                        <Swiper
                                            slidesPerView={1.3}
                                            spaceBetween={10}
                                            ref={sliderRef}
                                        >
                                            {mutualfunds?.length > 0 ? <>
                                                {mutualfunds?.map((items) => {
                                                    return (<>
                                                        <SwiperSlide>
                                                            <div className="popfunds mb-2" style={{ cursor: "pointer" }} onClick={() => { navigate(`/mutualfund-details/${items?.mf_id}`) }}>
                                                                <div className="popfunds-icon mb-2">
                                                                    <img src={items?.mf_image ? imgUrl + items?.mf_image : "/img/defaltimage.png"} />
                                                                </div>
                                                                <div className="popfunds-title">{items?.mf_scheme_name
                                                                }</div>
                                                            </div>
                                                        </SwiperSlide>
                                                    </>)
                                                })}
                                            </> : <>
                                                <SwiperSlide>
                                                    <Skeleton height="150px">
                                                    </Skeleton>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <Skeleton height="150px">
                                                    </Skeleton>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <Skeleton height="150px">
                                                    </Skeleton>
                                                </SwiperSlide>
                                            </>}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="page-title mb-2"><h6 className="mb-0">Popular Basket</h6>
                                        <div className='swiper-buttons__box sbbRight' style={{ alignItems: 'center' }} onClick={() => { navigate('/popular-basket') }} >
                                            See All
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="funslider mb-2">
                                        <Swiper
                                            slidesPerView={1.3}
                                            spaceBetween={10}
                                        // ref={sliderRef}
                                        >
                                            {popularBasketData.length > 0 ? <>
                                                {popularBasketData?.map((items) => {
                                                    return (<>
                                                        <SwiperSlide>
                                                            <div className="popfunds" >
                                                                <div className="popfunds-icon mb-2">

                                                                    <><img src={items?.pbgs_media != null ? popularBasketBaseUrl + items?.pbgs_media : "/img/defaltimage.png"} /></>
                                                                </div>
                                                                {items?.pbgs_basket_name ? <> <div className="popfunds-title">{items?.pbgs_basket_name
                                                                }</div></> : <Skeleton width="" />}
                                                                <a href={`/popularbasketdetails/${items?.pbgs_id}`} class="btn btn-outline-primary btn-sm mt-3 mb-0">Invest Now <i class="d-icon-angle-right"></i></a>
                                                            </div>
                                                        </SwiperSlide>
                                                    </>)
                                                })}
                                            </> :
                                                <>
                                                    <SwiperSlide>
                                                        <Skeleton height="150px">
                                                        </Skeleton>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <Skeleton height="150px">
                                                        </Skeleton>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <Skeleton height="150px">
                                                        </Skeleton>
                                                    </SwiperSlide>
                                                </>
                                            }
                                        </Swiper>
                                    </div>
                                </div>

                                {graphDashboardData.length > 0 ?
                                    graphDashboardData.map((value) => (
                                        <div className="col-lg-12">
                                            <div className="goalsection mb-2">
                                                <div className="goalsection-header mb-2" style={{ display: "block" }}>
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Goal: {value.goal_type.charAt(0).toUpperCase() + value.goal_type.slice(1).toLowerCase()} Goal for {value.goal_name}</h5>
                                                        <p className="mb-0 tx-gray tx-12">Goal End Date: {moment(value.created_at).add(value.goal_number_of_year, 'years').format('DD-MMM-YYYY')}</p>
                                                    </div>
                                                    <div>
                                                        <a className="btn btn-outline-primary btn-sm me-2" href={"/popular-basket/" + value.goal_id}>Invest In Any Of Popular Basket</a>
                                                        <a className="btn btn-outline-primary btn-sm" href={'/mutual-funds/' + value.goal_id}>Invest On Your Own</a>
                                                    </div>
                                                </div>

                                                <div className="goalsection-body">
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="goalsection-tcontent">
                                                                <div className="col-lg-6">
                                                                    <CustomcircularProgressBar height="128px" width="139px" percent={((difference_Goalyearss(value.created_at, value?.goal_monthly_investment_rounded_off) / totalGoalCalculate(value?.goal_monthly_investment_rounded_off, value?.goal_number_of_year)) * 100).toFixed(2)} color="#324dad" />

                                                                </div>

                                                                <div className="dflex align-items-center justify-content-between">
                                                                    <div className="text-center">
                                                                        <p className="mb-0 tx-gray tx-13">Goal</p>
                                                                        <h5 className="fw700 mb-0">{value.goal_type == "RETIREMENT" ? value.goal_corpus_needed : value.goal_actual_fund ? value.goal_actual_fund : '-'}</h5>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <p className="mb-0 tx-gray tx-13">Achieved</p>
                                                                        <h5 className="fw700 mb-0">-</h5>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="goalsection-tcontent">
                                                                <BarChart
                                                                    yAxis={[{ scaleType: 'band', data: ['Planned', 'Actual'] }]}

                                                                    series={[{ data: [totalGoalCalculate(value?.goal_monthly_investment_rounded_off, value?.goal_number_of_year), difference_Goalyearss(value.created_at, value?.goal_monthly_investment_rounded_off)] }]}
                                                                    layout="horizontal"
                                                                    width={300}
                                                                    height={200}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="goalsection-tcontent">
                                                                <div className="gdlist">
                                                                    <ul>
                                                                        <li style={{ width: '50%' }}>
                                                                            <div className="gdbox">
                                                                                <div className="gdbox-icon" ><img src="/img/g02.png" ></img></div>
                                                                                <div className="gdbox-content">
                                                                                    <p className="mb-0 tx-12 tx-gray">Start Date</p>
                                                                                    <h6 className="mb-0 fw700">{moment(value.created_at).format('DD/MM/YYYY')}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li style={{ width: '50%' }}>
                                                                            <div className="gdbox">
                                                                                <div className="gdbox-icon"><img src="/img/g03.png" ></img></div>
                                                                                <div className="gdbox-content">
                                                                                    <p className="mb-0 tx-12 tx-gray">End Date</p>
                                                                                    <h6 className="mb-0 fw700"> {moment(value.created_at).add(value.goal_number_of_year, 'years').format('DD/MM/YYYY')}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="gdlist">
                                                                    <ul>
                                                                        <li style={{ width: '50%' }}>
                                                                            <div className="gdbox">
                                                                                <div className="gdbox-icon"><img src="/img/g01.png" ></img></div>
                                                                                <div className="gdbox-content">
                                                                                    <p className="mb-0 tx-12 tx-gray">Goal Amount</p>
                                                                                    <h6 className="mb-0 fw700">{value.goal_type == "RETIREMENT" ? value.goal_corpus_needed : value.goal_actual_fund}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li style={{ width: '50%' }}>
                                                                            <div className="gdbox">
                                                                                <div className="gdbox-icon"><img src="/img/g04.png" ></img></div>
                                                                                <div className="gdbox-content">
                                                                                    <p className="mb-0 tx-12 tx-gray">Live</p>
                                                                                    <h6 className="mb-0 fw700">{value.goal_is_live == 1 ? "Yes" : "NO"}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>

                                                            <div className="asulist mt-2">
                                                                <p>Assumptions</p>

                                                                <ul>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">Inflation</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">{value.goal_expected_inflation}%</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">Return</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">{value.goal_expected_return}%</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">Mortality</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">{value.goal_type == "RETIREMENT" ? value.goal_mortality_age : "-"}</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">CAGR</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">-</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="asulist mt-4">
                                                                <ul>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">XIRP</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">-</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">SIP Required</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">{value.goal_monthly_investment_rounded_off}</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="asubox">
                                                                            <p className="mb-0 tx-gray tx-12">Lumsum Required</p>
                                                                            <div className="asubox-inner">
                                                                                <h6 className="tx-14">{value.goal_lumpsum_value}</h6>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ""}
                                <div className="col-lg-12">
                                    {/* <div className="panel mb-3 poniter">
                    <div className="panel-header"><h6 className="mb-0">Your Investments</h6></div>
                    <div className="panel-body">
                    <div className="dflex align-items-center">
                    <div>
                    <h6 className="mb-0 tx-green">+₹58,518</h6>
                    <p className="mb-0 tx-gray tx-12">Total Returns</p>
                    </div>
                    <div className="ml-auto text-right">
                    <h6 className="mb-0">₹2,20,009</h6>
                    <p className="mb-0 tx-gray tx-12">Current</p>
                    </div>
                    </div>
                    </div>
                    </div> */}

                                    <div className="panel mb-3">
                                        <div className="panel-body text-center p-5">
                                            <h6>Your Risk Profile</h6>
                                            {UserData?.gs_risk_assestment_status == 1 ? <><h6 className="tx-theme"><span>{UserData?.gs_risk_assestment_profile}</span> ({UserData?.gs_risk_assestment_score}) </h6></> : ""}
                                            {UserData?.gs_risk_assestment_status == 1 ? <><CustomcircularProgressBar percent={UserData?.gs_risk_assestment_score} height="150px" width="150px" /></> : <img src="img/holdings.svg" className="wd-150"></img>}
                                            <p>Record your findings in a Risk Assessment and management plan, and implement them </p>
                                            {UserData?.gs_risk_assestment_status == 0 ? <><a href="/risk-assessment" className="btn btn-outline-primary btn-md mt-2 mb-0">Risk assessment</a></> : ""}
                                        </div>
                                    </div>

                                    <div className="panel mb-3">
                                        <div className="panel-body text-center">
                                            <img src="img/holdings.svg" className="wd-150"></img>
                                            <p>Add goals & start investing if you can plan yourself and only want to help us in tracking. </p>
                                            <a href="/create-goals" className="btn btn-outline-primary btn-md mb-5">Create a New Goal</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomMenu></BottomMenu>
            </MobileView>
        </>
    )
}
export default Dashboard